.lable{
    display: flex;
    justify-content: center;
    align-items:center;
    height: 3rem;
    font-size: 0.7rem;
    // font-weight: 700;
}

.other{
    display: flex;
    margin: 0 auto;
    justify-content: center;
    width: 30%;
    color: var(--adm-color-primary);
}

.eye{
    span{
        font-size: 0.4Srem;
    }
    color: var(--adm-color-primary);
}