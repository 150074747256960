@buttonSize:2rem;
.image_upload_space{
    height: 100%;
    width: 100%;
    background-color: white;
}

.image_upload_button_wrap{
    position: relative;
    width: @buttonSize;
    height: @buttonSize;
    background-color:var(--adm-color-box) ;//var(--adm-color-box)
    margin-top: 0.2rem;
}

.image_upload_input{
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    left: 0;
    top: 0;
    
}
.image_upload_icon{
    color: rgb(84, 82, 86);
    margin: 0 auto;
    text-align: center;
    line-height: @buttonSize;
}
///////////////////////////////////////////////////

@margin_top:0.2rem;

//显示九宫格
.img_grid{

    margin-top: @margin_top;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 创建3列均等宽度的网格 */
    grid-gap: 0.1rem; /* 图片之间的间距 */
    img{
        object-fit: cover;
    }
}

//显示一张图片
.img_grid_one{
    display: block;
    margin-top: @margin_top;
    max-height: 5rem;
    width: auto;
    height: auto;
    // max-width: 100%;
    border-radius: 8px;
    
}
//显示两张图片
.img_grid_two{
    display: grid;
    margin-top: @margin_top;
    grid-template-columns: repeat(2, 1fr); /* 创建3列均等宽度的网格 */
    grid-gap: 0.1rem; /* 图片之间的间距 */
    img{
        object-fit: cover;
    }
}
//显示四张图片
.img_grid_four{
    display: grid;
    margin-top: @margin_top;
    width: 80%;
    grid-template-columns: repeat(2, 1fr); /* 创建3列均等宽度的网格 */
    grid-gap: 0.1rem; /* 图片之间的间距 */
    img{
        object-fit: cover;
    }
}


//滑动显示
.img_slide{
    display: flex;
    margin-top: @margin_top;
    width: 100%;
    height: 5.5rem;
    overflow-x: scroll;
    scrollbar-width: none;
    img{
        display: block;
        object-fit: cover;
        border-radius: 8px;
        min-width: 80%;
        
    }
    .img_prew:nth-child(n+1){
        margin-left: 0.1rem;
    }
}

//滑动模式只有一张
.img_slide_one{
    margin-top: 0.1rem;
    width: 100%;
    height:auto;
    object-fit: cover;
    max-height: 6rem;
}