body{
    background-color: var(--background-color2);
}
.tabs{
    position: fixed;
    bottom: 0;
    left:0;
    padding-bottom: 5px;
    width: 100%;
}

.textarea{
    margin-top: 0.2rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
}

.head_content{
    display: flex;
    justify-content: center;
    .select_button{
        margin-left: 20px;
    }
}

.img_show{
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    margin-bottom: 0.2rem;
}
.fabu_button{
    border: none;
    background-color: var(--adm-color-primary);
    border-radius: 15px;
    position: absolute;
    right: 10px;
    // top:10px;
    div{
        padding-left: 10px;
        padding-right: 10px;
        padding-top:5px ;
        padding-bottom:6px ;
        color: white;
    }
}