.body{
  display: flex;
  height: 100vh;
  .main{
    flex: 1;
  }
}

//内容展示区域


//底部导航栏

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  // border-top: solid 1px var(--adm-color-border);
  width: 100%;
  justify-content:space-between;
  background-color: white;
}