.header{
    position:fixed;
    // left: 100;
    top: 0;
    width: 100%;
}

.body{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.content{
    width: 70%;
    height: 20%;
    background-color: rgba(56, 56, 62, 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 100px;
}

.tip{
    width: 100vw;
    height: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #fff;
    margin-top: 1rem;
    background-color:var(--adm-color-primary);
}
.content h1{
    font-size: 25px;
    color: #fff;
    font-weight: 600;
}
.content p{
    font-size: 18px;

}

.btn {
    // width: 100%;
    // height: 40px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    // font-weight: 500;
    cursor: pointer;
    border: 1px solid #fff;
    box-sizing: border-box;
    padding: 4px 9px;
}